import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Website Integration - HD Camera Integration",
  "path": "/Advanced_User/Website_Integration/HD_Camera_Integration/ActiveX/",
  "dateChanged": "2017-09-26",
  "author": "Mike Polinowski",
  "excerpt": "Add your Cameras Image to your personal Homepage",
  "image": "./AU_SearchThumb_WebsiteIntegration_HD_Cams.png",
  "social": "/images/Search/AU_SearchThumb_WebsiteIntegration_HD_Cams.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_Integration_white.webp",
  "chapter": "Advanced User"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='Website Integration - Camera Integration ActiveX' dateChanged='2017-09-26' author='Mike Polinowski' tag='INSTAR IP Camera' description='Add your Cameras Image to your personal Homepage' image='/images/Search/AU_SearchThumb_WebsiteIntegration_HD_Cams.png' twitter='/images/Search/AU_SearchThumb_WebsiteIntegration_HD_Cams.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/Webseiten_Einbindung/HD_Kamera_Einbindung/ActiveX/' locationFR='/fr/Advanced_User/Website_Integration/HD_Camera_Integration/ActiveX/' crumbLabel="Website" mdxType="BreadCrumbs" />
 
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "webseiten-integration---hd-kameras-activex",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#webseiten-integration---hd-kameras-activex",
        "aria-label": "webseiten integration   hd kameras activex permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Webseiten Integration - HD Kameras ActiveX`}</h1>
    <h2 {...{
      "id": "integration-of-the-live-video-stream-in-your-website",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#integration-of-the-live-video-stream-in-your-website",
        "aria-label": "integration of the live video stream in your website permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Integration of the live video stream in your website`}</h2>
    <p>{`There is two ways to integrate the HD video stream of your camera in your website.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`h264`}</strong>{`: This method will use the H264 video stream of your camera. This will need a computer to have the Apple QuickTime Plugin installed. When using Internet Explorer an ActiveX Plugin will be needed.`}</li>
      <li parentName="ul"><strong parentName="li">{`MJPEG`}</strong>{`: This method will work with every web browser. We will recommend you to use this way of integration to make sure the video stream of your camera can be displayed in any web browser.`}</li>
    </ul>
    <h3 {...{
      "id": "edit-the-html-page",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#edit-the-html-page",
        "aria-label": "edit the html page permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Edit the HTML Page`}</h3>
    <p><strong parentName="p">{`Step 1`}</strong>{`: Please download our HTML package and unpack the ZIP file. The ZIP file can be found `}<a parentName="p" {...{
        "href": "https://wiki.instar.com/dl/Developer/INSTAR_HD_Video_in_Website.zip"
      }}>{`here`}</a>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a4352a4c05474310a2e5bba3c119ac8d/29d31/Stream_in_Website_HD_01_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "37.391304347826086%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAHABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAECBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHbgSwP/8QAFhABAQEAAAAAAAAAAAAAAAAAAAEh/9oACAEBAAEFAsSMf//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABUQAQEAAAAAAAAAAAAAAAAAABAx/9oACAEBAAY/Ao//xAAZEAADAAMAAAAAAAAAAAAAAAAAAREhUbH/2gAIAQEAAT8hVVdCc1E0P//aAAwDAQACAAMAAAAQDD//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPxBH/8QAFhEAAwAAAAAAAAAAAAAAAAAAAAER/9oACAECAQE/EKys/8QAGRAAAwEBAQAAAAAAAAAAAAAAARExACGB/9oACAEBAAE/EALwLFSYbZgux7v/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a4352a4c05474310a2e5bba3c119ac8d/e4706/Stream_in_Website_HD_01_EN.avif 230w", "/en/static/a4352a4c05474310a2e5bba3c119ac8d/d1af7/Stream_in_Website_HD_01_EN.avif 460w", "/en/static/a4352a4c05474310a2e5bba3c119ac8d/70e80/Stream_in_Website_HD_01_EN.avif 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a4352a4c05474310a2e5bba3c119ac8d/a0b58/Stream_in_Website_HD_01_EN.webp 230w", "/en/static/a4352a4c05474310a2e5bba3c119ac8d/bc10c/Stream_in_Website_HD_01_EN.webp 460w", "/en/static/a4352a4c05474310a2e5bba3c119ac8d/426ac/Stream_in_Website_HD_01_EN.webp 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a4352a4c05474310a2e5bba3c119ac8d/e83b4/Stream_in_Website_HD_01_EN.jpg 230w", "/en/static/a4352a4c05474310a2e5bba3c119ac8d/e41a8/Stream_in_Website_HD_01_EN.jpg 460w", "/en/static/a4352a4c05474310a2e5bba3c119ac8d/29d31/Stream_in_Website_HD_01_EN.jpg 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a4352a4c05474310a2e5bba3c119ac8d/29d31/Stream_in_Website_HD_01_EN.jpg",
              "alt": "HD Camera Integration",
              "title": "HD Camera Integration",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Download and unzip the files.`}</p>
    <p><strong parentName="p">{`Step 2`}</strong>{`: If you want to integrate the H264 video stream in your website please open the file h264_video.html in order to make modifications for your camera.`}</p>
    <p>{`In order to access your camera over the internet (PORTFORWARDING) or your internal network you have to fill in the DDNS or IP address of your camera. You will also need to type in the username and the password of your camera. Optional you can also select the video resolution which you want to display.`}</p>
    <p>{`Please change the following parameters:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` url `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'http://192.168.1.165:80'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` streamnum `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`11`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Videostream 11 for 1280 x 720 pixel, 12 for 640 x 352 pixel and 13 for 320 x 176 pixel resolution`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` name0 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'admin'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Camera Username`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` password0 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'instar'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Camera Password`}</span></code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e326cd42fd09b5595f7b0078876f04c3/29d31/Stream_in_Website_HD_02_DE.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "67.3913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIDAQX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB7rRY0mS//8QAFhABAQEAAAAAAAAAAAAAAAAAAhAx/9oACAEBAAEFAoqt/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8BR//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABQQAQAAAAAAAAAAAAAAAAAAACD/2gAIAQEABj8CX//EABoQAAICAwAAAAAAAAAAAAAAAAABMVEQEWH/2gAIAQEAAT8hU4kJ8Nqh6H//2gAMAwEAAgADAAAAEHQ//8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQMBAT8Qh//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/EKr/xAAbEAEAAgIDAAAAAAAAAAAAAAABABFBYRAx0f/aAAgBAQABPxBUTCD3wgNmINmhnULvcCmk/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e326cd42fd09b5595f7b0078876f04c3/e4706/Stream_in_Website_HD_02_DE.avif 230w", "/en/static/e326cd42fd09b5595f7b0078876f04c3/d1af7/Stream_in_Website_HD_02_DE.avif 460w", "/en/static/e326cd42fd09b5595f7b0078876f04c3/70e80/Stream_in_Website_HD_02_DE.avif 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e326cd42fd09b5595f7b0078876f04c3/a0b58/Stream_in_Website_HD_02_DE.webp 230w", "/en/static/e326cd42fd09b5595f7b0078876f04c3/bc10c/Stream_in_Website_HD_02_DE.webp 460w", "/en/static/e326cd42fd09b5595f7b0078876f04c3/426ac/Stream_in_Website_HD_02_DE.webp 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e326cd42fd09b5595f7b0078876f04c3/e83b4/Stream_in_Website_HD_02_DE.jpg 230w", "/en/static/e326cd42fd09b5595f7b0078876f04c3/e41a8/Stream_in_Website_HD_02_DE.jpg 460w", "/en/static/e326cd42fd09b5595f7b0078876f04c3/29d31/Stream_in_Website_HD_02_DE.jpg 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e326cd42fd09b5595f7b0078876f04c3/29d31/Stream_in_Website_HD_02_DE.jpg",
              "alt": "HD Camera Integration",
              "title": "HD Camera Integration",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Edit the h264_video.html file if you want to integrate the HD camera´s h.264 video stream.`}</p>
    <p><strong parentName="p">{`Step 3`}</strong>{`: If you want to integrate the MJPEG stream please modify the file mjpeg_video.html in the same way as explained in step 2 above.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/451f4a2394c1f874c97d1c8e8e347655/29d31/Stream_in_Website_HD_MJPEG_02_DE.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAgADBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHuLNBjS//EABgQAAIDAAAAAAAAAAAAAAAAAAIQAAEh/9oACAEBAAEFAkV6in//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAY/Al//xAAaEAACAwEBAAAAAAAAAAAAAAAAAREhMRBh/9oACAEBAAE/IU7J8IMFSuaw/9oADAMBAAIAAwAAABBkL//EABURAQEAAAAAAAAAAAAAAAAAAAAh/9oACAEDAQE/EEf/xAAVEQEBAAAAAAAAAAAAAAAAAAAAIf/aAAgBAgEBPxBX/8QAGxABAAIDAQEAAAAAAAAAAAAAAQARQVGRMWH/2gAIAQEAAT8QcFDVXUFv2EA4RLAI1hggePYxPxuf/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/451f4a2394c1f874c97d1c8e8e347655/e4706/Stream_in_Website_HD_MJPEG_02_DE.avif 230w", "/en/static/451f4a2394c1f874c97d1c8e8e347655/d1af7/Stream_in_Website_HD_MJPEG_02_DE.avif 460w", "/en/static/451f4a2394c1f874c97d1c8e8e347655/70e80/Stream_in_Website_HD_MJPEG_02_DE.avif 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/451f4a2394c1f874c97d1c8e8e347655/a0b58/Stream_in_Website_HD_MJPEG_02_DE.webp 230w", "/en/static/451f4a2394c1f874c97d1c8e8e347655/bc10c/Stream_in_Website_HD_MJPEG_02_DE.webp 460w", "/en/static/451f4a2394c1f874c97d1c8e8e347655/426ac/Stream_in_Website_HD_MJPEG_02_DE.webp 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/451f4a2394c1f874c97d1c8e8e347655/e83b4/Stream_in_Website_HD_MJPEG_02_DE.jpg 230w", "/en/static/451f4a2394c1f874c97d1c8e8e347655/e41a8/Stream_in_Website_HD_MJPEG_02_DE.jpg 460w", "/en/static/451f4a2394c1f874c97d1c8e8e347655/29d31/Stream_in_Website_HD_MJPEG_02_DE.jpg 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/451f4a2394c1f874c97d1c8e8e347655/29d31/Stream_in_Website_HD_MJPEG_02_DE.jpg",
              "alt": "HD Camera Integration",
              "title": "HD Camera Integration",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Edit the mjpeg_video.html file if you want to integrate the HD camera´s MJPEG video stream.`}</p>
    <h3 {...{
      "id": "upload-the-page-to-your-server",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#upload-the-page-to-your-server",
        "aria-label": "upload the page to your server permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Upload the Page to your Server`}</h3>
    <p><strong parentName="p">{`Step 4`}</strong>{`: Now please use a FTP client in order to upload the files to your web space. In our example we are using the software `}<strong parentName="p">{`FileZilla`}</strong>{`. If you are not so familiar with HTML we recommend you to upload all files in the same folder where your index.html file is located which is normally the main page of your website.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4c164a39e166ca5b5e58f53b795344d5/29d31/Stream_in_Website_HD_03_DE.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "42.608695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAJABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIDBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAdphyZQP/8QAGRAAAgMBAAAAAAAAAAAAAAAAAAEQESFC/9oACAEBAAEFAkijDqP/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAWEAADAAAAAAAAAAAAAAAAAAAAIEH/2gAIAQEABj8CIv8A/8QAGxAAAQUBAQAAAAAAAAAAAAAAAAERIWGREHH/2gAIAQEAAT8hQp/BkxgamCdf/9oADAMBAAIAAwAAABCjz//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EAB0QAAIBBAMAAAAAAAAAAAAAAAABMRARQVFxgcH/2gAIAQEAAT8QhNPpiJd3MdCObvwWaf/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4c164a39e166ca5b5e58f53b795344d5/e4706/Stream_in_Website_HD_03_DE.avif 230w", "/en/static/4c164a39e166ca5b5e58f53b795344d5/d1af7/Stream_in_Website_HD_03_DE.avif 460w", "/en/static/4c164a39e166ca5b5e58f53b795344d5/70e80/Stream_in_Website_HD_03_DE.avif 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4c164a39e166ca5b5e58f53b795344d5/a0b58/Stream_in_Website_HD_03_DE.webp 230w", "/en/static/4c164a39e166ca5b5e58f53b795344d5/bc10c/Stream_in_Website_HD_03_DE.webp 460w", "/en/static/4c164a39e166ca5b5e58f53b795344d5/426ac/Stream_in_Website_HD_03_DE.webp 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4c164a39e166ca5b5e58f53b795344d5/e83b4/Stream_in_Website_HD_03_DE.jpg 230w", "/en/static/4c164a39e166ca5b5e58f53b795344d5/e41a8/Stream_in_Website_HD_03_DE.jpg 460w", "/en/static/4c164a39e166ca5b5e58f53b795344d5/29d31/Stream_in_Website_HD_03_DE.jpg 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4c164a39e166ca5b5e58f53b795344d5/29d31/Stream_in_Website_HD_03_DE.jpg",
              "alt": "HD Camera Integration",
              "title": "HD Camera Integration",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Edit the mjpeg_video.html file if you want to integrate the HD camera´s MJPEG video stream.`}</p>
    <p><strong parentName="p">{`Step 4.1`}</strong>{`: Afterwards all files should be in the same folder as shown below.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/601c5552d01adb2f8d3a00a2e0b6f6ec/29d31/Stream_in_Website_HD_04_DE.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "45.65217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAJABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAIBBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAe1qiQf/xAAZEAACAwEAAAAAAAAAAAAAAAAAARARITH/2gAIAQEAAQUCSKRgux//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAWEAADAAAAAAAAAAAAAAAAAAABECD/2gAIAQEABj8Cgr//xAAcEAABBAMBAAAAAAAAAAAAAAAAATFBkRARIYH/2gAIAQEAAT8hQ3FDnEo8UMk4f//aAAwDAQACAAMAAAAQ8A//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAcEAEAAQQDAAAAAAAAAAAAAAABABAhMUGhscH/2gAIAQEAAT8QsyJcEhOnmE1nwPJ0U//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/601c5552d01adb2f8d3a00a2e0b6f6ec/e4706/Stream_in_Website_HD_04_DE.avif 230w", "/en/static/601c5552d01adb2f8d3a00a2e0b6f6ec/d1af7/Stream_in_Website_HD_04_DE.avif 460w", "/en/static/601c5552d01adb2f8d3a00a2e0b6f6ec/70e80/Stream_in_Website_HD_04_DE.avif 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/601c5552d01adb2f8d3a00a2e0b6f6ec/a0b58/Stream_in_Website_HD_04_DE.webp 230w", "/en/static/601c5552d01adb2f8d3a00a2e0b6f6ec/bc10c/Stream_in_Website_HD_04_DE.webp 460w", "/en/static/601c5552d01adb2f8d3a00a2e0b6f6ec/426ac/Stream_in_Website_HD_04_DE.webp 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/601c5552d01adb2f8d3a00a2e0b6f6ec/e83b4/Stream_in_Website_HD_04_DE.jpg 230w", "/en/static/601c5552d01adb2f8d3a00a2e0b6f6ec/e41a8/Stream_in_Website_HD_04_DE.jpg 460w", "/en/static/601c5552d01adb2f8d3a00a2e0b6f6ec/29d31/Stream_in_Website_HD_04_DE.jpg 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/601c5552d01adb2f8d3a00a2e0b6f6ec/29d31/Stream_in_Website_HD_04_DE.jpg",
              "alt": "HD Camera Integration",
              "title": "HD Camera Integration",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Edit the `}<strong parentName="p">{`mjpeg_video.html`}</strong>{` file if you want to integrate the HD camera´s MJPEG video stream.`}</p>
    <p><strong parentName="p">{`Step 5`}</strong>{`: All you need to do is either to link the file `}<em parentName="p">{`h264`}{`_`}{`video.html`}</em>{` or `}<em parentName="p">{`mjpeg`}{`_`}{`video.html`}</em>{` in your website. If you are familiar with HTML you can also simply integrate the HTML code in an existing site in order to integrate the video stream in any of your existing websites.`}</p>
    <p>{`In case of questions please feel free to contact us any time.`}</p>
    <p><strong parentName="p">{`UPDATE`}</strong>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "language-html"
      }}><code parentName="pre" {...{
          "className": "language-html"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`<!--
Man muss den unten stehenden Codeteil so abändern das die IP Adresse die dort jetzt hinterlegt ist, auf die DDNS Adresse verweist inkl. Port unter der die Kamera aus dem Internet erreichbar ist. Dort wo http... steht, wird die DDNS Adresse gefolgt von dem HTTP Port verwendet. Beispiel: abcd12.ddns3-instar.de:8081
Dort wo rtmp://... steht, da müssen Sie den RTMP Port anstatt dem HTTP Port angeben. Wichtig ist, das man diesen Port ebenfalls frei gibt im Router. So müssen Sie dort also eine Portweiterleitung für den HTTP und eine für den RTMP Port haben. Der RTMP Port ist by default 1935, wenn Sie eine zweite Kamera nutzen, muss der Port auf z.B. 1936 erhöht werden. Beim RTMP Port ist es zudem ganz wichtig das der interne und externe Port bei der Portweiterleitung immer identisch sind. Wenn die Kamera also intern den Port 1936 hat, dann muss dieser auch von extern freigegeben werden.
Des weiteren ist es wichtig, das man Benutzername und Passwort als Base64 Verschlüsselt abspeichert.
Im Codebeispiel ist YWRtaW46dmFsbGE z.B. der Teil der die Base64 Codierung von Benutzer und Passwort wiederspiegelt. Dazu müssen Sie Benutzername:Passwort codieren. Also z.B. admin:instar
Wichtig ist das der Benutzername mit einem Doppelpunkt getrennt wird vom Passwort.
Sie finden im Internet viele Seiten dazu wenn Sie nach "Base64 Encode" suchen.
-->`}</span>{`

`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`embed`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`src`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`http://192.168.2.125:80/flash/StrobeMediaPlayback.swf`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`mode`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`flash`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`width`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`960`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`height`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`540`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`flashvars`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span><span parentName="span" {...{
                "className": "token entity named-entity",
                "title": "&"
              }}>{`&amp;`}</span>{`src=rtmp://192.168.2.125:1935/flash/12:YWRtaW46aW5zdGFy=`}<span parentName="span" {...{
                "className": "token entity named-entity",
                "title": "&"
              }}>{`&amp;`}</span>{`streamType=live`}<span parentName="span" {...{
                "className": "token entity named-entity",
                "title": "&"
              }}>{`&amp;`}</span>{`autoPlay=true`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`type`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`application/x-shockwave-flash`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      